// Tabler
@import '~@tabler/core/src/scss/core';
@import '~@tabler/core/src/scss/tabler-vendors';

h3.modal-title > small {
  font-size: 16px;
  display: block;
}

.bg-white-lt {
  background: #fff;
  color: var(--tblr-body-color);
}

.bg-black-lt {
  background: #000;
  color: #fff;
}

.bg-light-green {
  background: #c5e0b3;
}

.bg-light-yellow {
  background: #fff2cc;
}

.bg-grey {
  background: #dadada;
}

.selectgroup-button-icon {
  line-height: 32px;

  > .avatar {
    margin-right: 5px;
  }
}

.avatar-md {
  line-height: 2.3rem;
}

.media-heading h5 {
  margin-top: .6em;
}

.errors > .invalid-feedback {
  display: block;
}

.form-group .progress {
  margin-top: 5px;
}

.form-control.is-valid + .selectize-control > .selectize-input {
  border-color: #5eba00;
}

table.dataTable {
  margin: 0 !important;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0;
}

.card-profile .card-header {
  height: auto;
  padding: 0;

  img {
    width: 100%;
  }
}

.dropzone {
  border: 2px dashed #a5a5a5;
  padding: 15px;
  text-align: center;
  width: 100%;

  i {
    font-size: 70px;
  }
}

.dz-image, .dz-details, .dz-success-mark, .dz-error-mark {
  display: none;
}

.selectize-dropdown [data-selectable] .note {
  font-size: 85%;
}

.col-login {
  max-width: 25rem;
}

a.logo {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: #000;
  font-size: 1.4375rem;

  &:hover {
    text-decoration: none;
  }

  > img {
    margin-right: 10px;
  }
}

.header-brand > img {
  margin-right: 10px;
}

.g-recaptcha-response ~ .invalid-feedback {
  display: block;
}

.g-recaptcha > div {
  height: auto !important;
}

.breadcrumb a, .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.selectize-dropdown .optgroup-header {
  font-weight: bold;
}

.card-body {
  .nav-tabs {
    margin: 0 -1.50rem;

    .nav-link {
      padding: 1rem 1.75rem;
    }
  }

  .tab-content {
    margin-top: 20px;
  }
}

.tab-content .form-group {
  position: relative;

  .btn-group {
    position: absolute;
    top: 30px;
    right: 0;
  }
}

.table {
  width: 100% !important;
}

.card .chart-circle-xs {
  position: absolute;
  right: 15px;
}

.preview .content {
  max-width: 370px;
}

.tinymce {
  position: relative;
  width: 100%;
  height: 700px;
  border: 1px solid #ebf1f7;
}

.w-40 {
  width: 40% !important;
}

table.dataTable td {
  &.disabled {
    color: var(--tblr-gray-300);

    span.badge {
      opacity: .3;
    }
  }

  .btn-action {
    width: auto;
    height: auto;

    &:hover {
      text-decoration: none;
    }
  }
}

.dropdown-item-icon {
  line-height: 20px;
}

.dt-button {
  position: absolute;
  top: 2px;
  right: 5px;
  background: none;
  border: none;
  padding: 0;
  opacity: .5;

  &:hover {
    opacity: 1;
  }
}

.grow-wrap {
  display: grid;

  > textarea,
  &::after {
    padding: .4375rem .75rem;
    grid-area: 1 / 1 / 2 / 2;
  }

  > textarea {
    resize: none;
    overflow: hidden;
  }

  &::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  #modal-preview .modal-dialog {
    max-width: 650px;
  }

  .list-timeline:not(.list-timeline-simple) {
    &:before {
      left: calc(8.5rem + 2px);
    }

    .list-timeline-icon {
      left: 7.5rem;
    }

    .list-timeline-content {
      padding-left: 11rem;
    }

    .list-timeline-time {
      width: 6.5rem;
    }
  }
}

@media (min-width: 992px) {
  .form-inline .form-control {
    width: 600px;
  }
}
